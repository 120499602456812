import { Directive, ElementRef, Renderer } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { TutorialService } from './tutorial.service';
import {TutoriaisComponent} from './tutoriais.component';
import {Component, Input} from '@angular/core';
interface tutoriais {
	link: string;
}

@Directive({
	selector: '[fundoAmarelo]'
})
export class TutorialDirective {

	postsTest: Observable<tutoriais[]>;
	@Input('categoria') cat;
	categoria: string;

	constructor(private calculadoraService:TutorialService,private TutoriaisComponent:TutoriaisComponent) {}

	ngOnInit() { 

		this.postsTest = null;
		this.categoria = null;
		this.TutoriaisComponent.posts = null;
		this.myfuction(this.cat);
		console.log("teste2 "+this.TutoriaisComponent.posts);
	} 

	myfuction (categoria){
	//	this.TutoriaisComponent.posts = this.calculadoraService.getVideosClinica();
		console.log(this.cat);
		console.log(this.TutoriaisComponent.posts);
	}
}