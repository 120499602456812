import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { User } from "../models/users";

import * as firebase from 'firebase/app';
import { AngularFireAuth } from 'angularfire2/auth';



@Injectable({
  providedIn: 'root'
})
export class LoginService {

  user: Observable<firebase.User>;

  constructor(private router: Router, public afAuth: AngularFireAuth) {
    this.user = afAuth.authState;
  }

  public login(mail: string, password: string) {

    return new Promise((resolve, reject) => {

      this.afAuth.auth.signInWithEmailAndPassword(mail, password).then((user) => {

        localStorage['token'] = user.user.uid;
        if(mail== 'boaideia@boaideianet.com.br'){
          this.router.navigate(['/formvideos']);
        }else{
          this.router.navigate(['/tutoriais']);
        }
      

      })
        .catch((error) => {
          alert('Usuario ou Senha incorreta');
          this.router.navigate(['/login']);
        });
    })
      .catch((error) => {
        alert('Usuario ou Senha incorreta');
        this.router.navigate(['/login']);
      });
  }

  
  public logout() {
    return this.afAuth.auth.signOut();
  }


}