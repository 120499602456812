import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Directive, ElementRef, Input, Output, EventEmitter } from '@angular/core';

interface tutoriais {
	link: string;
}

interface categoriasComercial {
	nome: string;
}

interface categoriasClinica {
	nome: string;
}

@Injectable({
	providedIn: 'root'
})
export class TutorialService {

	postsCol: AngularFirestoreCollection<tutoriais>;
	posts: Observable<tutoriais[]>;

	postsCol1: AngularFirestoreCollection<tutoriais>;
	posts1: Observable<tutoriais[]>;

	categoriaColComercial: AngularFirestoreCollection<categoriasComercial>;
	categoriaComercial: Observable<categoriasComercial[]>;

	categoriaColClinica: AngularFirestoreCollection<categoriasClinica>;
	categoriaClinica: Observable<categoriasClinica[]>;

	constructor(private db: AngularFirestore) { }

	getCategoriasSelecionada(cat){
		this.categoriaColClinica = this.db.collection('categorias');
		this.categoriaClinica = this.categoriaColClinica.valueChanges();
		return this.categoriaClinica;
	}

	getVideosComercial(cat){
		if(cat == ''){
			this.postsCol = this.db.collection('tutorial', ref => ref.where('empresa', '==', 'Comercial'));
		}else{
			this.postsCol = this.db.collection('tutorial', ref => ref.where('empresa', '==', 'Comercial').where('categoria', '==', cat));
		}
		
		this.posts = this.postsCol.valueChanges();
		return this.posts;
	}

	getVideosClinica(cat){
		if(cat == ''){
			this.postsCol1 = this.db.collection('tutorial', ref => ref.where('empresa', '==', 'Clínica'));
		}else{
			this.postsCol1 = this.db.collection('tutorial', ref => ref.where('empresa', '==', 'Clínica').where('categoria', '==', cat));
		}
		
		this.posts1 = this.postsCol1.valueChanges();
		return this.posts1;
	}
}
