import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from "./page-login/login.service";
//import { NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  public routeLoading: boolean = false;

  show: boolean = true;

  title = 'boaideia';
  isLoggin: boolean;

  name_home: string[] = ['Boa Ideia', 'Boa Ideia Consultoria & Informatica', 'Consultoria & Informatica'];
  home1: string;
  home2: string;

  constructor(private loginService: LoginService,
    /*private router: Router*/) {
    this.loginService.user.subscribe(user => {
      if (!user) {
        this.isLoggin = false;
      } else {
        this.isLoggin = true;
      }
    })

    /*
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.routeLoading = true;
      }

      if (event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError) {
        this.routeLoading = false;
      }
    });*/
  }

  //constructor(private router: Router) {



  ngOnInit() {
    this.home1 = this.name_home[0];
    this.home2 = "";
  }

  modifica_texto() { }
}
