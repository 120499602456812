import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from 'angularfire2/storage';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { map } from 'rxjs/operators';
import { LoginService } from '../../page-login/login.service';
import { Router } from '@angular/router';


interface tutoriais {
  descricao: string;
  empresa: string;
  link: string;
  categoria: string;
  id: string;
}

interface imagens {
  descricao: string;
  titulo: string;
  texto: string;
  url: string;
}

interface categoria {
  nome: string;
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  listvideosCol: AngularFirestoreCollection<tutoriais>;
  listvideos: Observable<tutoriais[]>;

  listimagensCol: AngularFirestoreCollection<imagens>;
  listimagens: Observable<imagens[]>;

  listvideosEdCol: AngularFirestoreCollection<tutoriais>;
  listvideosEd: Observable<tutoriais[]>;

  listcategoriaCol: AngularFirestoreCollection<categoria>;
  listcategoria: Observable<categoria[]>;

  public ideasCollection: AngularFirestoreCollection<tutoriais>;
  public ideas: Observable<any[]>;
  public form: FormGroup;
  empresaOption: string;
  isLoggin: boolean;

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;
  uploadProgress: Observable<number>;
  uploadState: Observable<string>;


  eventGlobal: any;

  constructor(private db: AngularFirestore, private readonly afs: AngularFirestore, private loginService: LoginService, private router: Router, private afStorage: AngularFireStorage) {
    this.form = new FormGroup({
      id: new FormControl(''),
      link: new FormControl('', Validators.required),
      descricao: new FormControl('', Validators.required),
      categoria: new FormControl(''),
      empresa: new FormControl('clinica'),
      titulo: new FormControl(''),
      texto: new FormControl(''),
      url: new FormControl('')
    })

    this.loginService.user.subscribe(user => {
      if (!user) {
        this.isLoggin = false;
        this.router.navigate(['/login']);
      } else {
        this.isLoggin = true;
        this.router.navigate(['/formvideos']);
      }
    })

  }

  ngOnInit() {

    this.empresaOption = 'clinica';
    this.listarTodosVideos();
    this.listarTodasCategorias();
    this.listarTodasImagens();

  }

  upload(event) {

    this.eventGlobal = event;

  }
  upload_firebase() {
    ////const id = Math.random().toString(36).substring(2);
    this.ref = this.afStorage.ref(this.form.controls['url'].value);
    //console.log("Evento:" + event.target.files[0]);
    this.task = this.ref.put(this.eventGlobal.target.files[0]);
    this.uploadState = this.task.snapshotChanges().pipe(map(s => s.state));
    this.uploadProgress = this.task.percentageChanges();

  }

  listarTodosVideos(): Observable<tutoriais[]> {
    this.listvideosCol = this.db.collection('tutorial');
    this.listvideos = this.listvideosCol.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as tutoriais;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }));
    return this.listvideos;
  }

  listarTodasImagens(): Observable<imagens[]> {
    this.listimagensCol = this.db.collection('imagens');
    this.listimagens = this.listimagensCol.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as imagens;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }));
    return this.listimagens;
  }




  listarTodasCategorias(): Observable<categoria[]> {
    this.listcategoriaCol = this.db.collection('categorias', ref => ref.orderBy('nome', 'asc'));
    this.listcategoria = this.listcategoriaCol.valueChanges();
    return this.listcategoria;
  }

  insertVideos() {
    return this.db.collection('tutorial').add({
      descricao: this.form.controls['descricao'].value,
      link: this.form.controls['link'].value,
      empresa: this.form.controls['empresa'].value,
      categoria: this.form.controls['categoria'].value,
    });
  }

  updateVideos() {
    this.db.doc('tutorial/' + this.form.controls['id'].value).update({
      descricao: this.form.controls['descricao'].value,
      link: this.form.controls['link'].value,
      empresa: this.form.controls['empresa'].value,
      categoria: this.form.controls['categoria'].value,
    })
  }

  preencherForm(id, descricao, link, empresa, categoria) {
    this.form.controls['id'].setValue(id);
    this.form.controls['descricao'].setValue(descricao);
    this.form.controls['link'].setValue(link);
    this.form.controls['empresa'].setValue(empresa);
    this.form.controls['categoria'].setValue(categoria);

    console.log(categoria);

  }

  preencherFormImagem(id, descricao, titulo, texto, url) {
    this.form.controls['id'].setValue(id);
    this.form.controls['descricao'].setValue(descricao);
    this.form.controls['titulo'].setValue(titulo);
    this.form.controls['texto'].setValue(texto);
    this.form.controls['url'].setValue(url);
    console.log("preencherFormImagem");
  }

  deleteVideo(id: string) {
    if (confirm('Deseja excluir videio ?')) {
      this.db.collection('tutorial').doc(id).delete();
    }

  }

  limparForm() {
    this.form.controls['id'].setValue('');
    this.form.controls['descricao'].setValue('');
    this.form.controls['link'].setValue('');
    this.form.controls['empresa'].setValue('clinica');
    this.form.controls['categoria'].setValue('Cadastro');
  }

  alterarCategoria(tarefa): void {
    if (tarefa == '') {
      this.listvideosCol = this.db.collection('tutorial');
    } else {
      this.listvideosCol = this.db.collection('tutorial', ref => ref.where('categoria', '==', tarefa));
    }
    this.listvideos = this.listvideosCol.valueChanges();
  }

  get f() { return this.form.controls; }

}
