import { Injectable } from '@angular/core';

import { Caracteristicas } from '../model/caracteristicas';
import { CARACTERISTICAS } from '../model/mock_caracteristica';

@Injectable({
  providedIn: 'root'
})
export class CaracteristicasHomeService {

  constructor() { }

  hello_word(){
    console.log("Hello Word");
  }

  getCaracteristicas(): Caracteristicas[] {
    return CARACTERISTICAS;
  }
}
