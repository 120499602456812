import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from 'angularfire2/firestore';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {TutorialService } from './tutorial.service';
import { TutorialDirective } from './tutorial.directive';
import { LoginService } from "../../page-login/login.service";
import {Router} from '@angular/router';

interface tutoriais {
  link: string;
}

interface categoriasComercial {
  nome: string;
}

interface categoriasClinica {
  nome: string;
}



@Component({
  selector: 'app-tutoriais',
  templateUrl: './tutoriais.component.html',
  styleUrls: ['./tutoriais.component.css']
})
export class TutoriaisComponent implements OnInit {


  url: string = "https://www.mmlpqtpkasjdashdjahd.com";
  urlSafe: SafeResourceUrl;
  categoria: Observable<categoriasComercial[]>;
  categoriaModelo: string;
  empresaModelo: string;
  public isLoggin: boolean;

  posts: Observable<tutoriais[]>;
  posts1: Observable<tutoriais[]>;

  constructor(public sanitizer: DomSanitizer, private TutorialService: TutorialService, private loginService: LoginService,private router: Router) {
   this.loginService.user.subscribe( user => {
     if(!user){
       this.isLoggin = false;
       this.router.navigate(['/login']);
     }else{
       this.isLoggin = true;
       this.router.navigate (['/tutoriais']);
     }
   })
  }

  ngOnInit() {

    this.categoriaModelo = 'Todos';
    // INICIANDO COM AS CATEGORIAS COMERCIAIS
    this.categoriaSelecionada('Comercial');

     // INICIANDO COM OS VIDEOS SEM FILTRO DE CATEGORIA
     this.listavideosClinica('');

    // INICIANDO COM OS VIDEOS SEM FILTRO DE CATEGORIA
    this.listavideosComercial('');

  }

  // LISTA CATEGORIA PELA EMPRESA QUE ESTA SELECIONANDA
  categoriaSelecionada(cat) {
    this.categoria = this.TutorialService.getCategoriasSelecionada(cat);
  }

  // LISTA VIDEOS DA EMPRESA/CATEGORIA SELECIONADA
  listavideosComercial(cat) {
    this.empresaModelo = 'Comercial';
    this.posts = this.TutorialService.getVideosComercial(cat);
  }

  // LISTA VIDEOS DA EMPRESA/CATEGORIA SELECIONADA
  listavideosClinica(cat) {
    this.empresaModelo = 'Clínica';
    this.posts1 = this.TutorialService.getVideosClinica(cat);
  }

  // MUDA CATEGORIA NO CLICK
  filtroCategoriaSelecao(cat, empresa) {
    
    this.categoriaModelo = cat;
    if (empresa == 'Clínica' || empresa == 'Comercial') {
      this.empresaModelo = empresa;
    }
    console.log("todos "+cat);
    if (this.empresaModelo == 'Clínica') {
     
      if (cat == 'Todos') {
        cat = '';
        this.posts1 = this.TutorialService.getVideosClinica(cat);
      } else {

        this.posts1 = this.TutorialService.getVideosClinica(cat);
      }
    }

    if (this.empresaModelo == 'Comercial') {
     
      if (cat == 'Todos') {
        cat = '';
      
        this.posts = this.TutorialService.getVideosComercial(cat);
      } else {
        this.posts = this.TutorialService.getVideosComercial(cat);
      }

    }
    if(cat == ''){
      this.categoriaModelo='Todos';
    }
  }

  photoURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl("https://www.mmlpqtpkasjdashdjahd.com");
  }

}





