import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desc-comercial',
  templateUrl: './desc-comercial.component.html',
  styleUrls: ['./desc-comercial.component.css']
})
export class DescComercialComponent implements OnInit {

  print: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
