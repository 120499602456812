import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-component-parceiros',
  templateUrl: './component-parceiros.component.html',
  styleUrls: ['./component-parceiros.component.css']
})
export class ComponentParceirosComponent implements OnInit {

  
  img_carrossel_1: any = "./assets/img/imagens_tiago/home/6.jpg";
  //img_carrossel_1: any = "https://firebasestorage.googleapis.com/v0/b/boaideianetinformatica.appspot.com/o/foto3?alt=media&token=f6675d09-978d-4780-8867-c9e9cb490614";
  img_carrossel_2: any = "./assets/img/imagens_tiago/home/1111.jpg";
  img_carrossel_3: any = "./assets/img/imagens_tiago/home/13.jpg";
  
  img_carrossel_p: any = "../assets/img/imagens_tiago/home/prontocor2.jpg";
  img_carrossel_t: any = "../assets/img/imagens_tiago/home/taiobeiras2.jpg";
  img_carrossel_b: any = "../assets/img/imagens_tiago/home/brasiliademinas2.jpg";
  
  
  constructor() { }

  ngOnInit() {
  }

}
