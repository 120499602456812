import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TutoriaisComponent } from './pages/tutoriais/tutoriais.component'
import { AppComponent } from './app.component'
import { HomeComponent } from './page-home/home/home.component';
import { ListComponent } from './page-formvideos/list/list.component';
import { ComponentParceirosComponent } from './page-home/parceiros/component-parceiros.component';
import { LoginComponent } from './page-login/login/login.component';
import { DescMedComponent } from './desc-med/desc-med.component';
import { ComponentContatoComponent } from './page-home/contato/component-contato.component';
import { DescComercialComponent } from './desc-comercial/desc-comercial.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'tutoriais', component: TutoriaisComponent },
  { path: 'home', component: HomeComponent },
  { path: 'formvideos', component: ListComponent },
  { path: 'parceiros', component: ComponentParceirosComponent },
  { path: 'login', component: LoginComponent },
  { path: 'desc_med', component: DescMedComponent },
  { path: 'contato', component: ComponentContatoComponent },
  { path: 'desc_comercial', component: DescComercialComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

  onActivate(event) {

    console.log("OI");
    /*console.log(event);
console.log("hello");
  
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 1); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);*/
  }
}
