import { Caracteristicas } from './caracteristicas';

export const CARACTERISTICAS: Caracteristicas[] = [
  { icon_material: 'autorenew', titulo: '1111111', texto:'AA111111111' },
  { icon_material: 'backup',    titulo: '2222222', texto:'AA222222222' },
  { icon_material: 'autorenew', titulo: '3333333', texto:'AA333333333' },
  { icon_material: 'autorenew', titulo: '4444444', texto:'AA444444444' },
  { icon_material: 'autorenew', titulo: '5555555', texto:'AA555555555' },
  { icon_material: 'autorenew', titulo: '6666666', texto:'AA666666666' },
  { icon_material: 'autorenew', titulo: '7777777', texto:'AA777777777' },
  { icon_material: 'autorenew', titulo: '8888888', texto:'AA888888888' },
  { icon_material: 'autorenew', titulo: '9999999', texto:'AA999999999' }
];