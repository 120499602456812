import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desc-med',
  templateUrl: './desc-med.component.html',
  styleUrls: ['./desc-med.component.css']
})
export class DescMedComponent implements OnInit {

  //IRA OCULTAR UMA DIV NO TEMPLATE
  //USANDO DA DIRETIVA *ngIf
  showDiv: boolean = false;

  constructor() { }

  ngOnInit() {
  }

}
