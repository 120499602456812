import { Component, OnInit } from '@angular/core';
import { CaracteristicasHomeService } from '../../shared/service/caracteristicas-home.service';
import { Caracteristicas } from '../../shared/model/caracteristicas';
import { Texto } from '../../shared/model/texto';
import { TEXTO } from '../../shared/model/mock_texto';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {


  img_carrossel_1: any = "./assets/img/imagens_tiago/home/img_car1.jpg";
  img_carrossel_2: any = "./assets/img/imagens_tiago/home/img_car2.jpg";
  img_carrossel_3: any = "./assets/img/imagens_tiago/home/img_car3.jpg";
  
  img_carrossel_p: any = "../assets/img/imagens_tiago/home/prontocor2.jpg";
  img_carrossel_t: any = "../assets/img/imagens_tiago/home/taiobeiras2.jpg";
  img_carrossel_b: any = "../assets/img/imagens_tiago/home/brasiliademinas2.jpg";
  
 
 
  faturamento: string;
  c : Caracteristicas[];
  constructor(private caracteristicasHomeService: CaracteristicasHomeService
    ) { }

  ngOnInit() {
    
    this.faturamento = TEXTO[0].faturamento;
    this.caracteristicasHomeService.hello_word();
    this.c = this.caracteristicasHomeService.getCaracteristicas();
  }

 
}
