import { Component, OnInit } from '@angular/core';
import { LoginService } from "../login.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private router: Router, private loginService: LoginService) { }

  usuario: string;
  password: string;

  ngOnInit() {
  }

  logOn() {
    this.loginService.login(this.usuario, this.password);
  }

  logout() {
    this.loginService.logout();
  }

  onKey(event: any) {
    if (event.key === 'Enter') {
      this.logOn();
    }
  }
}

