import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-component-contato',
  templateUrl: './component-contato.component.html',
  styleUrls: ['./component-contato.component.css']
})
export class ComponentContatoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
